<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card class="px-5 py-3" style="margin-top:10px">
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>検索条件</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="age_from" label="Age(from)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-text-field v-model.number="age_to" label="Age(to)" type="number"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select :items="['男性','女性']" clearable v-model="gender" label="性別"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select :items="['医療費・医療手当','死亡一時金・葬祭料','死亡一時金','葬祭料','障害年金']" clearable multiple v-model="types" label="請求内容"></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="disease" label="症状名、障害名"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select :items="approved_date_list" clearable v-model="selected_approved_date" label="認定日"></v-select>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div
          style="margin-top:10px; font-size:12px; text-align:right"
          v-if="search_conditions_text !==''"
        >
          <span>{{search_conditions_text}}</span>
          <v-icon
            id="copy_icon"
            title="検索条件をコピー"
            style="margin-left:5px;margin-right:10px"
            @click="copyURLToClipboard()"
          >mdi-content-copy</v-icon>
          <span>{{" 検索結果 : " + filteredItems.length +"件" }}</span>
          <input id="dummy" style="display:none" />
        </div>
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          mobile-breakpoint="0"
          style="white-space: pre;"
          :footer-props="footer_props"
          :loading="items.length === 0"
        >
        <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
          <template v-slot:item="i">
            <!-- Since v-slot:item overrides how each row is rendered, I rebuild the row starting from <tr>. This allows me to add a class to <tr> based on any condition I want (in this case, the calorie count) -->
            <tr>
              <td>{{i.item.gender}}</td>
              <td>{{i.item.age}}</td>
              <td
                :class="{
                      'red lighten-5 red--text': i.item.type.includes('死亡') || i.item.type.includes('葬祭')
              }"
              >{{i.item.type}}</td>
              <td>{{i.item.name}}</td>
              <td>{{i.item.basic_disease}}</td>
              <td>{{i.item.result}}</td>
              <td>{{i.item.approved_date}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<style>
table {
  table-layout: fixed;
}
.v-data-table td {
  white-space: pre-line;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 8px !important;
}
</style>

<script>
import axios from 'axios'
// import * as d3 from 'd3'
// import moment from 'moment'
// import apexchart from 'vue-apexcharts'
export default {
  name: 'Dashboard',

  // components: {
  //   apexchart: apexchart,
  // },

  data() {
    return {
      panel: 0,
      search_conditions_text: '',
      age_from: '',
      age_to: '',
      gender: '',
      disease: '',
      types: '',
      result: '',
      footer_props: {
        'items-per-page-options': [10, 50, 100, 200],
        showFirstLastPage: true,
      },
      headers: [
        {
          sortable: false,
          text: '性別',
          value: 'gender',
          width: '50px',
        },

        {
          text: '年齢',
          value: 'age',
          width: '50px',
        },
        {
          text: '請求内容',
          value: 'type',
          width: '150px',
        },
        {
          sortable: false,
          text: '症状名、障害名',
          value: 'name',
          width: '240px',
        },
        {
          sortable: false,
          text: '関連する基礎疾患及び既往症',
          value: 'basic_disease',
          width: '200px',
        },
        {
          sortable: false,
          text: '判定',
          value: 'result',
          width: '80px',
        },
        {
          sortable: false,
          text: '認定日',
          value: 'approved_date',
          width: '100px',
        },
      ],
      items: [],
      approved_date_list: [],
      selected_approved_date: '',
    }
  },

  computed: {
    filteredItems() {
      var conditions = []
      this.clearSearchConditionText()
      var text = ''
      if (this.age_to !== '' || this.age_from !== '') {
        if (text !== '') text += ' / '
        text += '年齢 : '
        if (this.age_from !== '') text += this.age_from + '歳以上'
        if (this.age_to !== '') text += this.age_to + '歳以下'
        conditions.push(this.filterByAge)
      }
      if (this.gender) {
        if (text !== '') text += ' / '
        text += '性別 : ' + this.gender
        conditions.push(this.filterByGender)
      }
      if (this.disease) {
        if (text !== '') text += ' / '
        text += '症状名、障害名 : ' + this.disease + 'を含む'
        conditions.push(this.filterByDisease)
      }
      if (this.types) {
        if (text !== '') text += ' / '
        text += '請求内容 : ' + this.types
        conditions.push(this.filterByType)
      }
      if (this.selected_approved_date) {
        if (text !== '') text += ' / '
        text += '認定日 : ' + this.selected_approved_date
        conditions.push(this.filterByApprovedDate)
      }
      if (conditions.length > 0) {
        text = '検索条件 : [ ' + text + ' ]'
        this.setSearchConditionText(text)
        return this.items.filter(item => {
          return conditions.every(condition => {
            return condition(item)
          })
        })
      }
      return this.items
    },
  },

  mounted() {
    if (this.$route.query.age_from) this.age_from = this.$route.query.age_from
    if (this.$route.query.age_to) this.age_to = this.$route.query.age_to
    if (this.$route.query.gender) this.gender = this.$route.query.gender
    if (this.$route.query.name) {
      this.disease = this.$route.query.name
    }
    if (this.$route.query.types) this.types = this.$route.query.types.split(',')
    if (this.$route.query.approved_date) this.selected_approved_date = this.$route.query.approved_date

    this.getJson()
  },

  watch: {
    $route(to, from) {
      this.getJson()
    },
  },

  methods: {
    clearSearchConditionText() {
      this.search_conditions_text = ''
    },
    setSearchConditionText(text) {
      this.search_conditions_text = text
    },
    filterByAge(item) {
      if (item.age === '') return false
      // var age = Number(item.age.replace('代', ''))
      if (this.age_from === '') return this.age_to >= item.age
      if (this.age_to === '') return this.age_from <= item.age

      return this.age_from <= item.age && this.age_to >= item.age
    },
    filterByGender(item) {
      return item.gender === this.gender
    },
    filterByDisease(item) {
      return item.name.includes(this.disease)
    },
    filterByType(item) {
      for (var type of this.types) {
        if (item.type === type) return true
      }
      return false
    },
    filterByApprovedDate(item) {
      return item.approved_date === this.selected_approved_date
    },

    getJson() {
      this.items = []
      var jsonFileName = 'approved.json'
      axios.get('/json/' + jsonFileName).then(
        function(res) {
          this.items = res.data
          for (var row of res.data) {
            if (!this.approved_date_list.includes(row.approved_date)) {
              this.approved_date_list.push(row.approved_date)
            }
          }
        }.bind(this),
      )
    },

    copyURLToClipboard() {
      var url = window.location.href.split('?')[0] + '?'

      var param = ''
      if (this.age_to !== '' || this.age_from !== '') {
        if (this.age_from !== '') param += '&age_from=' + this.age_from
        if (this.age_to !== '') param += '&age_to=' + this.age_to
      }
      if (this.gender !== '') {
        param += '&gender=' + this.gender
      }
      if (this.disease) {
        param += '&name=' + this.disease
      }
      if (this.types) {
        param += '&types=' + this.types
      }
      if (this.selected_approved_date) {
        param += '&approved_date=' + this.selected_approved_date
      }

      // clipboardがhttpでは動作しないため、別のやり方で回避
      // navigator.clipboard.writeText(url + param)
      const newInput = document.createElement('input')
      newInput.type = 'text'
      newInput.value = url + param
      const dummy = document.getElementById('dummy')
      dummy.parentNode.insertBefore(newInput, dummy.nextSibling)
      newInput.select()
      document.execCommand('copy')
      newInput.parentNode.removeChild(newInput)
      document.getElementById('copy_icon').focus()
    },
  },
}
</script>
